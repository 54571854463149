//Node Modules
import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";

// Components
import Map from "./map/Map";
import { CustomPrevArrow, CustomNextArrow } from "./components/CustomArrows";

//Scripts
import { convertStateToAbbr, truncateDecimals } from "./utils.js";

//Slick Slider CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//Constants
const wordpressRootUrl = window.location.origin;
const rootElement = document.getElementById("ess-root");
const shortcode = rootElement.getAttribute("data-shortcode");
const essURL = "https://www.extraspace.com";
const PHX_CDN_LINK =
  "https://ca1d1a92c69a28979902-4e283f56fd84008061dd018c7b04c794.ssl.cf2.rackcdn.com/";

const intentFilter = rootElement.getAttribute("data-intent") || null;
const title = rootElement.getAttribute("data-title") || null;
const description = rootElement.getAttribute("data-description") || null;
const mapActive = rootElement.getAttribute("data-map") || "1";

const App = () => {
  const [pageContent, setPageContent] = useState();
  const [stores, setStores] = useState([]);
  const [activeStoreId, setActiveStoreId] = useState(undefined);
  const [center, setCenter] = useState(
    stores && stores.length
      ? {
        lat: stores[0].latitude,
        lng: stores[0].longitude,
      }
      : undefined
  );

  const retrieveData = async () => {
    try {
      // Retrieve shortcode city/state
      const city = rootElement.getAttribute("data-city") || null;
      const state = rootElement.getAttribute("data-state") || null;

      let response = await axios.post(
        `${wordpressRootUrl}/wp-content/plugins/extraspace-api-connector/api/dynamicPricing.php`,
        { city: city, state: state }
      );

      if (response.status === 200 && response.data)
        setPageContent({
          cityContent: response.data?.content?.city?.fields,
          cityDetails:
            response?.data?.content?.entries?.items?.length > 0
              ? response?.data?.content?.entries?.items[0].fields
              : null,
          dynamicPrices: response.data?.dynamicPrices,
        });
    } catch (error) {
      console.log("Error calling ESS dynamic pricing endpoint. Error: ", error);
    }
  };

  const retrieveStores = async () => {
    try {

      // Retrieve shortcode lat/lng
      const lat = rootElement.getAttribute("data-lat") || null;
      const lng = rootElement.getAttribute("data-lng") || null;

	  let response = await axios.post(
        `${wordpressRootUrl}/wp-content/plugins/extraspace-api-connector/api/nearbyStores.php`,
        { lat: lat, lng: lng }
      );

      if (response.status === 200 && response.data) {
        setPageContent({
          stores: response?.data?.stores?.searchCards,
          center_lat: response?.data?.center_lat,
          center_lng: response?.data?.center_lng,
        });
      }
    } catch (error) {
      console.log("Error calling ESS dynamic pricing endpoint. Error: ", error);
    }
  };

  const renderIntentContent = () => {
    const { cityDetails, cityContent } = pageContent;
    const { cityName, stateName } = cityDetails;
    const city = `${cityName.charAt(0).toUpperCase()}${cityName.slice(1)}`;
    const state = `${stateName.charAt(0).toUpperCase()}${stateName.slice(1)}`;
    const firstColumnLabel = cityContent.dynamicPricingFirstColumnLabel || null;
    const middleColumnLabel = cityContent.dynamicPricingMiddleColumnLabel || null;
    const lastColumnLabel = cityContent.dynamicPricingLastColumnLabel || null;

    const wordpressH2WithReplacedState = title
      ? title.replace(/#state#/g, convertStateToAbbr(state))
      : "";
    const wordpressH2WithReplacedCity = wordpressH2WithReplacedState.replace(/#city#/g, city);

    const wordpressDescWithReplacedState = description
      ? description.replace(/#state#/g, convertStateToAbbr(state))
      : "";
    const wordpressDescWithReplacedCity = wordpressDescWithReplacedState.replace(/#city#/g, city);

    const fallbackH2WithReplacedState = cityContent.defaultDynamicPricingH2
      ? cityContent.defaultDynamicPricingH2.replace(/#state#/g, convertStateToAbbr(state))
      : "";
    const fallbackH2WithReplacedCity = fallbackH2WithReplacedState.replace(/#city#/g, city);

    const fallbackDescWithReplacedState = cityContent.defaultDynamicPricingDescription
      ? cityContent.defaultDynamicPricingDescription.replace(/#state#/g, convertStateToAbbr(state))
      : "";
    const fallbackDescWithReplacedCity = fallbackDescWithReplacedState.replace(/#city#/g, city);

    const dynamicPricingH2 = wordpressH2WithReplacedCity
      ? wordpressH2WithReplacedCity
      : cityDetails.dynamicPricingH2
        ? cityDetails.dynamicPricingH2
        : fallbackH2WithReplacedCity;
    const dynamicPricingDescription = wordpressDescWithReplacedCity
      ? wordpressDescWithReplacedCity
      : cityDetails.dynamicPricingDescription
        ? cityDetails.dynamicPricingDescription
        : fallbackDescWithReplacedCity;
    const disclaimer = cityContent.dynamicPricingDisclaimer
      ? cityContent.dynamicPricingDisclaimer
      : null;

    let priceResults = intentFilter
      ? [...pageContent.dynamicPrices].filter((el) => el.display === intentFilter)
      : [...pageContent.dynamicPrices];
    return (
      <>
        {pageContent && (
          <div className="dynamic-prices">
            {pageContent.dynamicPrices.length > 0 && (
              <>
                <h2>{dynamicPricingH2}</h2>
                <p>{dynamicPricingDescription}</p>
                <div className="flex-table">
                  <div className="flex-table-head">
                    <div className="flex-table-row">
                      <div className="flex-table-col">{firstColumnLabel}</div>
                      <div className="flex-table-col">{middleColumnLabel}</div>
                      <div className="flex-table-col">{lastColumnLabel}</div>
                    </div>
                  </div>
                  <div className="flex-table-body">
                    {priceResults.map((data, index) => (
                      <div className="flex-table-row" key={index}>
                        <div className="flex-table-col">{data.display}</div>
                        <div className="flex-table-col">${data.average}</div>
                        <div className="flex-table-col">
                          <a
                            href={`${essURL}/storage/facilities/us/${data.slugs.state}/${data.slugs.city}/${data.ozStoreId}/`}
                          >
                            ${data.lowest}
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <p className="pricing-disclaimer">{disclaimer}</p>
              </>
            )}
          </div>
        )}
        <style jsx>{`
          h2 {
            text-transform: uppercase;
            font-size: 36px;
          }

          .flex-table {
            display: flex;
            flex-flow: column wrap;
          }

          .flex-table-head,
          .flex-table-body {
            width: 100%;
          }

          .flex-table-body {
            margin-bottom: 20px;
          }

          .flex-table-head .flex-table-row {
            display: flex;
            flex-flow: row wrap;
            padding: 8px 0;
            border: 0;
          }

          .flex-table-head .flex-table-col {
            font-size: 20px;
            font-weight: bold;
          }

          .flex-table-head .flex-table-col:not(:first-of-type) {
            text-align: center;
          }

          .flex-table-body .flex-table-row {
            display: flex;
            flex-flow: row wrap;
            padding: 8px 0;
          }

          .flex-table-col {
            width: 33%;
          }

          .flex-table-body .flex-table-col:first-of-type {
            padding-left: 14px;
          }

          .flex-table-body .flex-table-col:not(:first-of-type) {
            text-align: center;
          }

          /* MOBILE */
          .pricing-disclaimer {
            text-align: right;
          }
        `}</style>
      </>
    );
  };

  const renderNearbyStores = () => {
    const settings = {
      dots: false,
      centerMode: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <CustomNextArrow />,
      prevArrow: <CustomPrevArrow />,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <>
        <div className="nearby-stores">
          <div className="nearby-stores-carousel">
            <Slider {...settings}>
              {pageContent.stores &&
                pageContent.stores.map((store, index) => {
                  const { address, postalCode, city, stateAbbreviation } = store;

                  const storeNumber =
                    String(store.number).length < 4 ? `0${String(store.number)}` : store.number;
                  const imageUrl = `${PHX_CDN_LINK}350x263-${storeNumber}.jpg`;
                  const cityNameSlug = store.city.toLowerCase().replace(/ /g, "_");
                  const stateNameSlug = store.state.toLowerCase().replace(/ /g, "_");
                  const facilityURL = `https://www.extraspace.com/storage/facilities/us/${stateNameSlug}/${cityNameSlug}/${store.ozStoreId}`;
                  return (
                    <div className="slide-card">
                      <div className="facility-image">
                        <img
                          className="dica-item-image"
                          alt={store.name}
                          height="350"
                          src={imageUrl}
                        />
                      </div>
                      <div className="facility-info">
                        <h4>
                          {address}, <br />
                          {city}, {stateAbbreviation} {postalCode}
                        </h4>
                        <div
                          class="Stars"
                          style={{
                            "--rating": 1,
                            "margin-bottom": "20px",
                          }}
                          title={`${store.reviewSummary.averageRating} stars`}
                          aria-label={`Rating of this product is ${store.reviewSummary.averageRating} out of 5.`}
                        >
                          <p>
                            {truncateDecimals(store.reviewSummary.averageRating, 1)}{" "}
                            <span>({store.reviewSummary.totalCount} reviews)</span>
                          </p>
                        </div>
                        <div className="footer-facility">
                          <a href={facilityURL} target="_blank" rel="noreferrer">
                            SELECT
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Slider>
          </div>
          {mapActive !== "0" && (
            <div className="map-container">
              <div className="map-section">
                <Map
                  activeStoreId={activeStoreId}
                  center={{
                    lat: parseFloat(pageContent.center_lat),
                    lng: parseFloat(pageContent.center_lng),
                  }}
                  defaultZoom={10}
                  locations={pageContent.stores}
                  setActiveStoreId={setActiveStoreId}
                  setCenter={setCenter}
                />
              </div>
            </div>
          )}
        </div>
        <style jsx>{`
          :root {
            --star-size: 15px;
            --star-color: #f1f1f1;
            --star-background: rgb(145, 199, 23);
          }

          div.nearby-stores > * {
            font-family: Arial, Helvetica, sans-serif;
          }

          .map-container {
            display: flex;
            margin-bottom: 1em;
          }

          .map-section {
            width: 100%;
            min-height: 250px;
          }

          .slick-track > .slick-slide > div {
            margin: 20px 10px;
          }

          .slick-list {
            padding: 50px 0;
          }

          .slick-slider > button.slick-arrow::before,
          .slick-slider > button.slick-arrow::after {
            color: #000;
            font-weight: 700;
            font-family: "Font Awesome 5 Free";
          }
          .slick-slider > button.slick-prev::before {
            content: "\\f053";
          }

          .slick-slider > button.slick-next::before {
            content: "\\f054";
          }

          .Stars {
            --percent: calc(var(--rating) / 1 * 100%);

            display: flex;
            font-size: var(--star-size);
            line-height: 1;
          }

          .Stars::before {
            content: "★";
            letter-spacing: 3px;
            background: linear-gradient(
              90deg,
              var(--star-background) var(--percent),
              var(--star-color) var(--percent)
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .Stars p {
            font-weight: 700;
            color: #54565b;
          }

          .Stars p span {
            font-weight: 300;
            text-transform: uppercase;
          }

          .facility-image img {
            width: 100%;
            max-height: 200px;
            object-fit: cover;
          }

          .slide-card {
            border-radius: 8px;
            overflow: hidden;
            box-shadow: 1px 7px 10px 2px rgba(0, 0, 0, 0.19);
            -webkit-box-shadow: 1px 7px 10px 2px rgba(0, 0, 0, 0.19);
            -moz-box-shadow: 1px 7px 10px 2px rgba(0, 0, 0, 0.19);
          }

          .slide-card .facility-info {
            background: #fff;
            padding: 15px 25px;
          }

          .facility-info h4 {
            color: rgb(84, 86, 91);
          }

          .footer-facility {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
          }

          .footer-facility a {
            color: #fff !important;
            background: rgb(254, 131, 0);
            background-color: #0f8ae7;
            padding: 10px 25px;
            border-radius: 50px;
          }

          .footer-facility .map-location {
            font-weight: 700;
            text-transform: uppercase;
          }
        `}</style>
      </>
    );
  };

  useEffect(() => {
    if (shortcode && shortcode === "ess_dynamic_prices") retrieveData();
    else if (shortcode && shortcode === "nearby_stores") retrieveStores();
  }, []);

  if (shortcode === "ess_dynamic_prices" && pageContent?.dynamicPrices)
    return renderIntentContent();
  if (shortcode === "nearby_stores" && pageContent) return renderNearbyStores();
  else return null;
};

export default App;
