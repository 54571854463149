// Node Modules
import React from "react";

// Components
import Marker, { markerHeight } from "./Marker";

const MapMarker = ({
  location,
  shortLink,
  index,
  active,
  onClick,
  setActiveStoreId,
  hoveredStoreId,
}) => {
  const { name, address, city, state, postalCode, ozStoreId } = location;

  // get formatted name of facility
  const formattedName = "Extra Space Storage";

  // create link
  const cityNameSlug = city.toLowerCase().replace(/ /g, "_");
  const stateNameSlug = state.toLowerCase().replace(/ /g, "_");
  const link =
    shortLink ||
    `https://www.extraspace.com/storage/facilities/us/${stateNameSlug}/${cityNameSlug}/${ozStoreId}`;

  const hovered = hoveredStoreId === ozStoreId;
  const color = hovered ? "#0f8ae7" : "#91C717";

  const markerClicked = () => {
    onClick();
    setActiveStoreId(ozStoreId);
  };

  const popoverWidth = "190px";

  return (
    <>
      <div>
        {active && (
          <div className="map-marker-popover">
            <div className="close-map-marker-popover-button-container">
              <button
                className="close-map-marker-popover-button"
                title="Close"
                onClick={() => setActiveStoreId(undefined)}
              >
                X
              </button>
            </div>
            <a className="map-marker-popover-link" href={link} rel="noreferrer">
              <div className="map-marker-popover-content">
                <div className="map-marker-popover-location-name">{formattedName}</div>
                <span className="card-link" rel="noreferrer">
                  <span>{address}</span>
                  <br />
                  <span>
                    {city}, {state} {postalCode}
                  </span>
                </span>
              </div>
            </a>
            <div className="map-marker-popover-bubble" />
          </div>
        )}
        <button className="map-marker-button" onClick={markerClicked}>
          <Marker map color={color} index={index + 1} title={name} />
        </button>
      </div>
      <style jsx>
        {`
          .map-marker-button {
            background: none;
            border: none;
            width: 20px;
            height: ${markerHeight};
            position: absolute;
            transform: translate(-50%);
            bottom: 0;
            z-index: ${active ? 2 : hovered ? 1 : 0};
            overflow: visible;
          }

          .map-marker-button:hover {
            cursor: pointer;
          }

          .map-marker-popover {
            position: absolute;
            transform: translate(-50%, calc(-${markerHeight} - 100% - 7px));
            display: inline-block;
            background-color: white;
            width: ${popoverWidth};
            border-radius: 8px;
            box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
            z-index: 10;
          }

          .close-map-marker-popover-button-container {
            width: 100%;
            text-align: right;
          }

          .close-map-marker-popover-button {
            font-size: 1em;
            color: #979797;
            cursor: pointer;
            background: none;
            border: none;
            margin: 2px 2px 0 0;
          }

          .close-map-marker-popover-button:hover {
            color: black;
          }

          .map-marker-popover-content {
            padding: 1em;
            padding-top: 0;
            font-size: 1.2em;
          }

          .card-link {
            height: calc(100% - ${markerHeight} + 1em);
            width: 100%;
            z-index: 1;
            left: 0;
            color: #54565b;
          }

          .card-link:hover {
            cursor: pointer;
          }

          .map-marker-popover-location-name {
            color: #91c717;
          }

          .map-marker-popover-bubble {
            width: 12px;
            height: 12px;
            background-color: white;
            position: absolute;
            transform: translate(calc(${popoverWidth} / 2 - 4px), -60%) rotate(45deg);
          }
        `}
      </style>
    </>
  );
};

export default MapMarker;
