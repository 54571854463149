export const markerHeight = "26px";

const Marker = ({ index, title }) => {
  return (
    <>
      <div className="marker">
        <span className="marker-label">{index}</span>
        <span className="marker-fa-icon">
          <i class="fas fa-map-marker" title={title}></i>
        </span>
      </div>
      <style jsx>
        {`
          .marker {
            background: none;
            border: none;
            width: 20px;
            height: ${markerHeight};
            transform: translate(-25%);
            margin: 0;
          }
          .marker .marker-fa-icon {
            color: #91c717;
            font-size: 27px;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1em;
          }
          .marker-label {
            color: white;
            font-weight: bold;
            font-size: 13px;
            z-index: 5;
            position: relative;
            width: 100%;
            text-align: center;
            display: inline-block;
            transform: translate(0, 5px);
          }
        `}
      </style>
      <style global jsx>
        {`
          .marker svg {
            width: 0.75em;
          }
          .fa-map-marker {
            filter: drop-shadow(0 0 1px #777);
          }
        `}
      </style>
    </>
  );
};

export default Marker;
