// Node Modules
import GoogleMapReact from "google-map-react";

// Components
import MapMarker from "./MapMarker";

const ESS_GOOGLE_API_KEY = "AIzaSyCZnOdlhfYlHc-7-ATA-_AONsSOocx2m9I";

const Map = ({
  activeStoreId,
  center,
  defaultZoom,
  fullscreenControl = true,
  hoveredStoreId,
  locations,
  mapTypeControl = true,
  setActiveStoreId,
  setCenter,
  streetViewControl = true,
  zoomControl = true,
}) => {
  const setSelectedLocation = (location) => {
    const { ozStoreId, latitude, longitude } = location;

    setActiveStoreId(ozStoreId);
    setCenter({
      lat: latitude,
      lng: longitude,
    });
  };

  const getMapOptions = (maps) => {
    return {
      clickableIcons: false,
      fullscreenControl,
      fullscreenControlOptions: {
        position: maps.ControlPosition.TOP_LEFT,
      },
      mapTypeControl,
      mapTypeControlOptions: {
        mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE],
        position: maps.ControlPosition.BOTTOM_CENTER,
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      },
      streetViewControl,
      streetViewControlOptions: {
        position: maps.ControlPosition.RIGHT_MIDDLE,
      },
      zoomControl,
    };
  };

  return (
    <>
      <div className="google-map-container" style={{ width: "100%", height: "400px" }}>
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={{
            key: ESS_GOOGLE_API_KEY,
          }}
          center={center}
          options={getMapOptions}
          zoom={defaultZoom}
          onDragEnd={() => setCenter(undefined)}
          onZoomAnimationEnd={() => setCenter(undefined)}
        >
          {locations &&
            locations.length > 0 &&
            locations
              .slice(0)
              .reverse()
              .map((location, index) => {
                // reversely place markers on map (so that 1 displays above 2, etc.)
                const { ozStoreId, latitude, longitude, shortLink } = location;

                return (
                  <MapMarker
                    active={activeStoreId === ozStoreId}
                    hoveredStoreId={hoveredStoreId}
                    index={locations.length - 1 - index}
                    key={ozStoreId}
                    lat={latitude}
                    lng={longitude}
                    location={location}
                    setActiveStoreId={setActiveStoreId}
                    shortLink={shortLink}
                    onClick={() => setSelectedLocation(location)}
                  />
                );
              })}
        </GoogleMapReact>
      </div>
    </>
  );
};

export default Map;
