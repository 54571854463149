export const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <i class="fa fa-chevron-left" style={{ color: "#979797", fontSize: 24 }}></i>
    </div>
  );
};

export const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <i class="fa fa-chevron-right" style={{ color: "#979797", fontSize: 24 }}></i>
    </div>
  );
};
