const stateFullNameAbbr = {
  arizona: "AZ",
  alabama: "AL",
  alaska: "AK",
  arkansas: "AR",
  california: "CA",
  colorado: "CO",
  connecticut: "CT",
  districtofcolumbia: "DC",
  delaware: "DE",
  florida: "FL",
  georgia: "GA",
  hawaii: "HI",
  idaho: "ID",
  illinois: "IL",
  indiana: "IN",
  iowa: "IA",
  kansas: "KS",
  kentucky: "KY",
  louisiana: "LA",
  maine: "ME",
  maryland: "MD",
  massachusetts: "MA",
  michigan: "MI",
  minnesota: "MN",
  mississippi: "MS",
  missouri: "MO",
  montana: "MT",
  nebraska: "NE",
  nevada: "NV",
  newhampshire: "NH",
  newjersey: "NJ",
  newmexico: "NM",
  newyork: "NY",
  northcarolina: "NC",
  northdakota: "ND",
  ohio: "OH",
  oklahoma: "OK",
  oregon: "OR",
  pennsylvania: "PA",
  rhodeisland: "RI",
  southcarolina: "SC",
  southdakota: "SD",
  tennessee: "TN",
  texas: "TX",
  utah: "UT",
  vermont: "VT",
  virginia: "VA",
  washington: "WA",
  westvirginia: "WV",
  wisconsin: "WI",
  wyoming: "WY",
  alberta: "AB",
  britishcolumbia: "BC",
  manitoba: "MB",
  newbrunswick: "NB",
  newfoundland: "NF",
  northwestterritory: "NT",
  novascotia: "NS",
  nunavut: "NU",
  ontario: "ON",
  princeedwardisland: "PE",
  quebec: "QC",
  saskatchewan: "SK",
  yukon: "YT",
};

export const convertStateToAbbr = (input) => {
  const strStateToFind = input.toLowerCase().replace(/\ /g, "");
  const foundAbbr = stateFullNameAbbr[strStateToFind];
  return foundAbbr;
};

export const truncateDecimals = (num, digits) => {
  var numS = num.toString(),
    decPos = numS.indexOf("."),
    substrLength = decPos == -1 ? numS.length : 1 + decPos + digits,
    trimmedResult = numS.substr(0, substrLength),
    finalResult =
      substrLength === 1
        ? isNaN(trimmedResult)
          ? 0
          : `${trimmedResult}.0`
        : isNaN(trimmedResult)
        ? 0
        : trimmedResult;

  return finalResult;
};
